<template>
	<div class="pui-form">
		<v-form @submit.prevent class="mb-4 pb-4" ref="form" v-model="valid" lazy-validation v-if="modelLoaded">
			<div row wrap class="pui-form-layout">
				<pui-field-set :title="$t('pmdatasourceinstance.title')">
					<v-layout wrap>
						<v-flex xs12 :md4="!isSuperAdmin" :md3="isSuperAdmin">
							<pui-text-field
								:label="$t('pmdatasourceinstance.name')"
								v-model="model.name"
								required
								toplabel
								maxlength="150"
							></pui-text-field>
						</v-flex>
						<v-flex xs12 :md4="!isSuperAdmin" :md3="isSuperAdmin">
							<pui-text-field
								:label="$t('pmdatasourceinstance.code')"
								v-model="model.code"
								required
								toplabel
								maxlength="150"
							></pui-text-field>
						</v-flex>
						<v-flex xs12 :md4="!isSuperAdmin" :md3="isSuperAdmin">
							<pui-text-field :label="$t('pmdatasourceinstance.ip')" v-model="model.ip" toplabel maxlength="30"></pui-text-field>
						</v-flex>
						<v-flex xs12 md4 v-if="isSuperAdmin">
							<pui-select
								attach="pmorganizationid"
								:label="this.$t('pmdatasourceinstance.organization')"
								:placeholder="this.$t('pmdatasourceinstance.phorganization')"
								toplabel
								clearable
								required
								reactive
								:disabled="formDisabled"
								v-model="model"
								modelName="vluppmorganization"
								:modelFormMapping="{ pmorganizationid: 'pmorganizationid' }"
								:itemsToSelect="organizationItemsToSelect"
								itemValue="pmorganizationid"
								itemText="name"
							></pui-select>
						</v-flex>
					</v-layout>
					<v-layout wrap>
						<v-flex xs12 md3>
							<pui-select
								attach="pmdatasourcetypeid"
								:label="this.$t('pmdatasourceinstance.pmdatasourcetype')"
								:placeholder="this.$t('pmdatasourceinstance.phdatasourcetype')"
								toplabel
								clearable
								required
								reactive
								:disabled="hasOrganization"
								v-model="model"
								modelName="vluppmdatasourcetype"
								:modelFormMapping="{ pmdatasourcetypeid: 'pmdatasourcetypeid' }"
								:itemsToSelect="datasourcetypeItemsToSelect"
								itemValue="pmdatasourcetypeid"
								itemText="name"
								:fixedFilter="datasourcetypeFilter"
							></pui-select>
						</v-flex>
						<v-flex xs12 md3>
							<pui-select
								attach="pmareaid"
								:label="this.$t('pmdatasourceinstance.pmarea')"
								:placeholder="this.$t('pmdatasourceinstance.pharea')"
								toplabel
								clearable
								required
								reactive
								:disabled="hasOrganization"
								v-model="model"
								modelName="vluppmarea"
								:modelFormMapping="{ pmareaid: 'pmareaid' }"
								:itemsToSelect="areaItemsToSelect"
								itemValue="pmareaid"
								itemText="name"
								:fixedFilter="areaFilter"
							></pui-select>
						</v-flex>
						<v-flex xs12 md3>
							<pui-select
								attach="pmproviderid"
								:label="this.$t('pmdatasourceinstance.pmprovider')"
								:placeholder="this.$t('pmdatasourceinstance.phprovider')"
								toplabel
								clearable
								required
								reactive
								:disabled="hasOrganization"
								v-model="model"
								modelName="vluppmprovider"
								:modelFormMapping="{ pmproviderid: 'pmproviderid' }"
								:itemsToSelect="providerItemsToSelect"
								:fixedFilter="datasourcetypeFilter"
								itemValue="pmproviderid"
								itemText="name"
							></pui-select>
						</v-flex>
						<v-flex xs12 md3>
							<pui-text-field :label="$t('pmdatasourceinstance.sla')" v-model="model.sla" toplabel min="0" max="100" decimals="2">
							</pui-text-field>
						</v-flex>
						<v-flex xs12>
							<pui-field-set :title="$t('pmdatasourceinstance.deliveryperiod')">
								<v-layout wrap>
									<v-flex xs12 md2>
										<pui-checkbox
											:label="$t('pmdatasourceinstance.overwritedeliveryperiod')"
											:tooltipDescription="$t('pmdatasourceinstance.tooltipDeliveryPeriod')"
											class="mt-4"
											v-model="model.overwritedeliveryperiod"
											:true-value="1"
											:false-value="0"
										></pui-checkbox>
									</v-flex>
									<v-flex xs12 md5>
										<pui-text-field
											:label="$t('pmdatasourceinstance.deliveryperiod')"
											v-model="model.deliveryperiod"
											toplabel
											min="1"
											:disabled="model.overwritedeliveryperiod == 0"
											:required="isDeliveryPeriodRequired || model.overwritedeliveryperiod == 1"
										>
										</pui-text-field>
									</v-flex>
									<v-flex xs12 md5>
										<pui-select
											attach="pmtimeunitid"
											:label="this.$t('pmdatasourceinstance.timeunit')"
											toplabel
											clearable
											reactive
											v-model="model"
											modelName="vluppmtimeunit"
											:modelFormMapping="{ pmtimeunitid: 'pmtimeunitid' }"
											:itemsToSelect="timeunitItemsToSelect"
											itemValue="pmtimeunitid"
											itemText="name"
											:disabled="model.overwritedeliveryperiod == 0"
											:required="isTimeUnitRequired || model.overwritedeliveryperiod == 1"
										></pui-select>
									</v-flex>
								</v-layout>
							</pui-field-set>
						</v-flex>
					</v-layout>

					<v-layout wrap>
						<v-flex xs12>
							<pui-text-area
								v-model="model.description"
								:label="$t('pmdatasourceinstance.description')"
								maxlength="250"
								toplabel
							></pui-text-area>
						</v-flex>
					</v-layout>
					<v-layout wrap>
						<v-flex xs12 md4>
							<pui-checkbox
								:label="$t('pmdatasourceinstance.disabled')"
								v-model="model.disabled"
								true-value="1"
								false-value="0"
								style="margin-top: -1em !important"
							></pui-checkbox>
						</v-flex>
						<v-flex xs12 md4>
							<pui-checkbox
								:label="$t('pmdatasourceinstance.ismobile')"
								v-model="model.ismobile"
								true-value="1"
								false-value="0"
								style="margin-top: -1em !important"
							></pui-checkbox>
						</v-flex>
					</v-layout>
					<pui-field-set :title="$t('pmdatasourceinstance.location')">
						<v-layout wrap>
							<v-flex xs12 md6>
								<pui-text-field
									:label="$t('pmdatasourceinstance.longitude')"
									v-model="model.longitude"
									:required="!isMobileDevice"
									toplabel
									maxlength="150"
								>
								</pui-text-field>
							</v-flex>
							<v-flex xs12 md6>
								<pui-text-field
									:label="$t('pmdatasourceinstance.latitude')"
									v-model="model.latitude"
									:required="!isMobileDevice"
									toplabel
									maxlength="150"
								>
								</pui-text-field>
							</v-flex>
						</v-layout>
					</pui-field-set>
				</pui-field-set>
			</div>
			<!-- footer -->
			<pui-form-footer v-if="!isModalDialog">
				<pui-form-footer-btns
					:formDisabled="formDisabled"
					:saveDisabled="saving"
					:saveAndNew="saveAndNew"
					:saveAndUpdate="saveAndUpdate"
					:save="validateAndSave"
					:back="back"
				></pui-form-footer-btns>
			</pui-form-footer>
		</v-form>
		<pui-form-loading v-else></pui-form-loading>
	</div>
</template>

<script>
import PuiFormMethodsMixin from 'pui9-mixins/PuiFormMethodsMixin';

import { isSuperAdmin } from '@/api/common';

export default {
	name: 'pmdatasourceinstanceform',
	mixins: [PuiFormMethodsMixin],

	data() {
		return {
			modelName: 'pmdatasourceinstance',
			datasourcetypeFilter: null,
			areaFilter: null,
			providerFilter: null,
			isSuperAdmin: false,
			isDeliveryPeriodRequired: false,
			isTimeUnitRequired: false
		};
	},
	watch: {
		model: {
			handler: function () {
				if (this.model.deliveryperiod || this.model.pmtimeunitid) {
					this.isDeliveryPeriodRequired = this.isTimeUnitRequired = true;
				} else if (!this.model.deliveryperiod && !this.model.pmtimeunitid) {
					this.isDeliveryPeriodRequired = this.isTimeUnitRequired = false;
				}
			},
			deep: true
		},
		'model.pmorganizationid': function (oldValue, newValue) {
			console.log('debug organization', oldValue, newValue, !!(newValue && oldValue));

			if (newValue && oldValue && newValue != oldValue) {
				this.model.pmdatasourcetypeid = null;
				this.model.pmproviderid = null;
				this.model.pmareaid = null;
			}
		}
	},
	computed: {
		datasourcetypeItemsToSelect() {
			return [{ pmdatasourcetypeid: this.model.pmdatasourcetypeid }];
		},
		areaItemsToSelect() {
			return [{ pmareaid: this.model.pmareaid }];
		},
		providerItemsToSelect() {
			return [{ pmproviderid: this.model.pmproviderid }];
		},
		timeunitItemsToSelect() {
			return [{ pmtimeunitid: this.model.pmtimeunitid }];
		},
		organizationItemsToSelect() {
			return [{ pmorganizationid: this.model.pmorganizationid }];
		},
		hasOrganization() {
			return !this.model.pmorganizationid;
		},
		isMobileDevice() {
			return this.model.ismobile == 1;
		}
	},
	created() {
		this.isSuperAdmin = isSuperAdmin(this.session.profiles[0]);
		if (this.model) {
			this.modelLoaded = true;
		}
	},
	mounted() {
		console.log('debug mounted', this.model, this.model.pmorganizationid);
		if (!this.isSuperAdmin) {
			this.datasourcetypeFilter =
				this.areaFilter =
				this.providerFilter =
					{
						groups: [],
						groupOp: 'and',
						rules: [{ field: 'pmorganizationid', op: 'eq', data: this.model.pmorganizationid || this.userProperties.organizationid }]
					};
		}
	},
	beforeUpdate() {},
	updated() {
		if (!this.model.pmorganizationid && !this.isSuperAdmin) {
			console.log('Hello', this.userProperties, this.userProperties.organizationid);
			this.model.pmorganizationid = this.userProperties.organizationid;
		}
	},
	methods: {
		isDeviceTypeEnabled(id) {
			let deviceType = this.$store.getters.getDatasourcetype.find((datasourcetype) => datasourcetype.pmdatasourcetypeid == id);
			console.log('devicetype debug', this.$store.getters.getDatasourcetype, deviceType);
			if (deviceType && deviceType.disabled == 0) {
				return true;
			}
			return false;
		},
		validateAndSave() {
			//this.insertLayer(); DEPRECATED
			console.log('device debug', this.model.disabled, this.model.pmdatasourcetypeid, this.isDeviceTypeEnabled(this.model.pmdatasourcetypeid));
			if (this.model.overwritedeliveryperiod == 0) {
				this.model.deliveryperiod = null;
				this.model.pmtimeunitid = null;
			}
			if (this.model.disabled == 0 && this.model.pmdatasourcetypeid) {
				if (!this.isDeviceTypeEnabled(this.model.pmdatasourcetypeid)) {
					console.log('device debug err');
					this.$puiNotify.error(
						this.$t('pmdatasourceinstance.actions.disableErrorForm'),
						this.$t('pmdatasourceinstance.actions.cannotEnable')
					);
					this.saving = false;
					return;
				} else {
					console.log('device debug save 1');
					this.save();
				}
			} else {
				console.log('device debug save 2');
				this.save();
			}
		}
	}
};
</script>
